/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap'); */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container-firma{
  background-image: url('./images/firma/Slide-firma-1.jpg') !important;
  min-height: 375.9px!important; 
  background-attachment: fixed!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  width: 100%!important;
}
.container-firma-2{
  background-image: url('./images/firma/Slide-firma-2.jpg') !important;
  min-height: 580.9px!important; 
  /* background-attachment: fixed!important; */
  background-position: center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  width: 100%!important;
}
.container-firma-3{
  background-image: url('./images/firma/Slide-firma-3.jpg') !important;
  min-height: 600.9px!important; 
  /* background-attachment: fixed!important; */
  background-position: center!important;
  background-position-y: -250px !important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  width: 100%!important;
}
.container-firma-4{
  background-image: url('./images/firma/Slide-firma-4.jpg') !important;
  min-height: 800.9px!important; 
  /* background-attachment: fixed!important; */
  background-position: center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  width: 100%!important;
}
.pd-4 {
  padding-top: 8%;
}
.pdb-5 {
  padding-bottom: 10%;
}
.pd-5 {
  padding-top: 10%;
}
.linea-1{
  border-right: 1px solid white;
  /* border-left:1px solid #3D4245; */
  height: 1px;
  max-width: 150px;
  background: linear-gradient(90deg, #FFFFFF 50%, #384043 50%);
  padding: 0;
  margin: 0 auto 0 auto;
  rotate: 90deg;
}
.linea-2{
  border-right: 1px solid #384043;
  /* border-left:1px solid #3D4245; */
  height: 1px;
  max-width: 150px;
  background: linear-gradient(90deg, #384043 50%, #FFFFFF 50%);
  padding: 0;
  margin: 0 auto 0 auto;
  rotate: 90deg;
}
.linea-3{
  border-right: 1px solid white;
  border-left: 1px solid #3D4245;
  height: 1px;
  max-width: 800px;
  background: var(--primary);
  padding: 0;
  margin: 0 auto 0 auto;
}
.pd-into{
  padding: 10% 10% 10%;
  padding-top: 10%!important;
}
.pd-into2{
  padding-top: 7%!important;
  padding-bottom: 5%!important ;
  padding-left: 5%;
  padding-right: 5%;
}
.oblique {
  font-style: oblique;
}
.text-bold{
  font-weight: bold;
}
.pl.item{
  text-align: justify !important;
}
#p-list{
  counter-reset: li !important; 
  list-style: none !important; 
  list-style: decimal !important; 
  font: 15px !important;
  padding: 0 !important;
}
#p-list ol {
  margin: 0 0 0 2em !important; 
}
#p-list li{
  position: relative !important;
  display: block !important;
  padding: 10px 2em 0.2em 4em !important;
  margin: .5em 0 !important;
  text-decoration: none !important;
  border-radius: .5em !important;
  transition: all .3s ease-out !important;   
}
#p-list li:before{
  content: counter(li);
  counter-increment: li;
  position: absolute; 
  left:-0.5em;
  font-size: 25px;
  margin-top: -0.5em;
  background:  rgb(198, 174, 109);
  color: #fff;
  height: 2.5em;
  width: 2.5em;
  line-height: 2em;
  border: .3em solid #fff;
  text-align: center;
  border-radius: 50%;
  transition: all .3s ease-out;
  margin-right: 20px;
}
/* .pl-list .pl-item{
  padding-left: 10%;
}
li.pl-item::marker{
  border: solid 1px #61dafb !important;
  font-size: 10px;
} */
.c-float{
  position: absolute;
  top: -125px;
  right: 7%;
  text-align: justify;
}
.white_box{
  position: relative;
}
.form-control{
  border-radius: 0px!important;
}
.react-tel-input .form-control{
  width: 90% !important;
}

form.register-form .form-control {
  padding: 10px;
  margin: 15px 0;
  border: none;
  border-bottom: solid 1px var(--primary);
  font-size: 18px;
  letter-spacing: 1px;
  transition: 0.1s;
}
.react-tel-input{
  font-family: inherit !important;
  font-size: 15px!important;
  margin-top: 4.3%!important;
}
.react-tel-input .form-control:focus{
  font-family: inherit !important;
}
.react-tel-input .form-control{
  padding: 23.5px!important;
  /* margin: 15px 0!important; */
  margin-top: 15px!important; 
  margin-left:10%!important;
  border: none!important;
  border-bottom: solid 1px var(--primary)!important;
  font-size: 18px!important;
  letter-spacing: 1px!important;
  transition: 0.1s!important;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 50px!important;
  height: 100%;
  padding: 0 0 0 8px !important;
  border-radius: 3px 0 0 3px !important;
}
.react-tel-input .flag-dropdown {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  padding: 0 !important;
  background-color: var(--gray);
  border: none !important;
  border-bottom: 1px solid #C7AD67!important;
  border-radius: 3px 0 0 3px !important;
}
form.register-form .form-control:focus {
  font-family: inherit !important;
  border-bottom: 1px solid #C7AD67!important;
}
.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
form.register-form .form-control {
  background-color: var(--gray);
}
form.register-form .form-control:focus {
  background-color: white;
  box-shadow: none !important;
  border-color: #ebebeb;
  font-family: Arial, Helvetica, sans-serif;
}
form.register-form .form-control:focus {
  background-color: white
}

/* .react-tel-input .flag-dropdown {
  background-color: #CACACA;
} */
.form-check-input-register {
  padding-left: 0px;
}
.form-check-label-register {
  padding-left: 10px;
}
/* .form-check-input-register {
  border: 1px solid #ebebeb;
  background-size: 100% 100%;
  width: 300px;
  height: 300px;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  padding: 0;
  user-select: none;
  flex-shrink: 0;
  color: var(--primary);
  background-color: #ebebeb;
  border-color: #555;
  border-width: 1px;
} */

.form-check-input-register {
  cursor: pointer;
  border-radius: 20%;
  background-color: var(--gray);
  width: 14px;
  height: 14px;
  appearance: none;
  border: 1px solid #CACACA;
  transition: background-color 0.3s ease-in-out;
}

.form-check-input-register:checked {
  background-color: var(--primary);
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
.form-label-politic-register{
  border-bottom: 0.2px solid;
  font-weight: 400;
}
.form-label-politic-register:hover{
  color: var(--primaryHover);
}
.button-register{
  width: 12%;
  padding: 15px;
  text-align: center;
  background: transparent;
  border: solid 1px #888;
  color: #555;
}
input.button-register:hover{
  background-color: var(--secondary);
  color: var(--gray);
  transition: background-color 0.3s ease-in-out;
}
.form-check-input-filter-f {
  width: 38px;
  height: 38px;
  margin-top: 0;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0,0,0,0.25);
  appearance: none;
  color-adjust: exact;
  border-radius: 8px;
  border: 1px solid #ced4da !important;
  margin-left: 15px;
}
.form-check-filter {
  padding-left: 0!important;
  text-align: left !important;
}
.form-check-inline-f {
  display: inline-block;
  margin-right: 0rem;
}
.form-check-input-filter-f:checked {
  background-color: var(--primary);
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
.form-check-label-filter-f {
  margin-top: 7px;
  margin-left: 0px;
}
.form-check-label-filter-m {
  margin-top: 7px;
  margin-left: 15px;
}
@media (max-width: 1400px) {
  .container-firma-3 {
    background-position-y: -150px !important;
  }
}
@media (max-width: 992px) {
  .container-firma-3 {
    background-position-y: -100px !important;
  }
}
@media (max-width: 768px) {
  .container-firma-3 {
    background-position-y: center !important;
    min-height: 350.9px !important;
  }
  .form-check-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .form-check-filter .form-check-input-filter-f {
    order: 1;
    margin-right: 15px;
  }
  
  .form-check-filter .form-check-label-filter-f {
    order: 2;
  }
  .form-check-input-filter-f {
    margin-left: 0px;
  }
}
@media (max-width: 992px) {
  .button-register{
    width: 100%;
  }
  .white_box{
    position: relative;
  }
  .c-float{
    position: relative;
    right: 0;
  }
  #p-list li{
    /* position: absolute !important; */
    display: block !important;
    padding: 10px 7em 0.2em 4em !important;
    margin: .5em 0 !important;
    text-decoration: none !important;
    border-radius: .5em !important;
    transition: all .3s ease-out !important;   
  }
}
@media (max-width: 768px) {
  .button-register{
    width: 100%;
  }
  .c-float{
    position: relative;
  }
  .white_box{
    position: relative;
  }
  #p-list li {
    position: relative !important;
    padding: 10px 7em 0.2em 4em !important;
    margin: 0.5em 0 !important;
    text-decoration: none !important;
    border-radius: 0.5em !important;
    transition: all .3s ease-out !important;
}
}
@media (max-width: 820px) {
  .button-register{
    width: 100%;
  }
  .pdb-5 {
    padding-top:12%;
    padding-bottom: 10%;
  }
  .pd-into2 {
    padding-top: 2%!important;
    padding-bottom: 5%!important;
    padding-left: 5%;
    padding-right: 5%;
  }
  #p-list li{
    position: relative !important;
    padding: 10px 7em 0.2em 4em !important;
    margin: .5em 0 !important;
    text-decoration: none !important;
    border-radius: .5em !important;
    transition: all .3s ease-out !important;   
  }
}
@media (max-width: 768px) {
 
}
@media (max-width: 992px) {
}